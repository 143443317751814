import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
  <article className="post-item">
    <Link to={`/blog/${post.frontmatter.slug}`}>
      <header>
        <div className="post-meta">{post.frontmatter.date}</div>
        <h2 className="post-title">{post.frontmatter.title}</h2>
        <p className="post-description">{post.frontmatter.metaDescription}</p>
      </header>
    </Link>
  </article>
)
export default PostLink
