import React from "react"

const Sidebar = () => (
  <div className="sidebar">
    <div className="sidebar-inner">
      <div className="sidebar-author-image">
        <img src="/assets/profile.jpg" alt="" />
      </div>
      <div className="sidebar-author-name">
        Nemoto Tatsuro
      </div>
      <div className="sidebar-author-text">
        Freelance Frontend/Serverside Engineer<br />
        フロントエンドもサーバーサイドもやるフリーランスエンジニア。<br />
        大学で分子生物学を専攻後、東日本大震災の直後に中小SIerへ就職。<br />
        その後ベンチャーへ転職し、2017年にフリーランスへ転向しました。
      </div>
    </div>
  </div>
)
export default Sidebar